// extracted by mini-css-extract-plugin
export var appear = "styles-module--appear--Hjnl5";
export var appearDown = "styles-module--appear-down--xvFtL";
export var appearLeft = "styles-module--appear-left--khdf4";
export var appearRight = "styles-module--appear-right--z1kCu";
export var appearUp = "styles-module--appear-up--Ii7En";
export var content = "styles-module--content--y6Wox";
export var desktop = "1340px";
export var giant = "2200px";
export var largeDesktop = "1600px";
export var largeMobile = "500px";
export var largeTablet = "1025px";
export var mobile = "400px";
export var preview = "styles-module--preview--ZUy2F";
export var smallDesktop = "1260px";
export var smallMobile = "340px";
export var smallTablet = "660px";
export var tablet = "769px";
export var wrapper = "styles-module--wrapper--r2TV+";